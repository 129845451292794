<template>
  <VAppBar color="white" elevation="2">
    <VAppBarNavIcon v-if="mobile" @click.stop="emit('click:navIcon')" />
    <slot />
  </VAppBar>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

const emit = defineEmits<{
  'click:navIcon': []
}>()

const { mobile } = useDisplay()
</script>

<style scoped></style>
